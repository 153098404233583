import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import Layout from "@embracesbs/component-layout";
import styled from "styled-components";
import Dashboard from "../Dashboard/Dashboard";
import Settings from "../Settings/Settings";
import Migrations from "../Migrations/Migrations";
import { theme } from "@embracesbs/helpers";
import DashboardSidebar from "../Shared/Sidebar";
const api = require("../Api/embraceAzure");

export default function App() {
  const [pat, setPat] = useState(localStorage.getItem("embracePat"));

  const [releaseDefinitions, setReleaseDefinitions] = useState([]);

  const excludedEnvironments = [
    "Cucumber smoke tests Portal",
    "Cucumber Tests Portal",
    "Experimental",
    "HackatonPortal-CD",
    "HackatonUmbraco-CD",
    "Smoke Tests",
    "Smoke tests customers",
    "Smoke Tests Portal R37",
    "Langewaard - Gewoon (obsolete)",
  ];

  const ContentWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: auto;
  `;

  function ExludeEnvironemt(name) {
    for (let i = 0; i < excludedEnvironments.length; i++) {
      if (excludedEnvironments[i] === name) {
        return false;
      }
    }
    return true;
  }

  useEffect(() => {
    if (pat) {
      api.GetAllDefinitions(pat).then((x) => {
        let newValues = x.value
          .filter((x) => ExludeEnvironemt(x.name))
          .sort(function (a, b) {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
        setReleaseDefinitions(newValues);
      });
    }
  }, [pat]);

  document.body.style.background = theme.color.light;
  document.body.style.margin = 0;

  return (
    <Router>
      <Layout columns="4rem 1fr" hasScrollbar={false}>
        <DashboardSidebar releaseDefinitions={releaseDefinitions} pat={pat} />
        <ContentWrapper>
          <Switch>
            <Route path="/dashboard">
              <Dashboard pat={pat} releaseDefinitions={releaseDefinitions} />
            </Route>
            <Route path="/migrations">
              <Migrations pat={pat} releaseDefinitions={releaseDefinitions} />
            </Route>
            <Route path="/settings">
              <Settings pat={pat} setPat={setPat} />
            </Route>
            <Route path="/">
              <Dashboard pat={pat} releaseDefinitions={releaseDefinitions} />
            </Route>
          </Switch>
        </ContentWrapper>
      </Layout>
    </Router>
  );
}
