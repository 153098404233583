import React from 'react';
import { Container } from "../Shared/Styles";
import styled from 'styled-components';
import MigrationCustomer from "./MigrationCustomer";

import Text from '@embracesbs/component-text';
import Card from '@embracesbs/component-card';
import CardHeader from '@embracesbs/component-cardheader';
import CardContent from '@embracesbs/component-cardcontent';
import AccordionList from '@embracesbs/component-accordionlist';

export default function Migrations({releaseDefinitions}) {

    const CustomerGrid = styled.div`
        display: grid;
        margin-top: 20px; 
        grid-gap: 15px; 
        grid-template-columns: repeat(${1}, 1fr);
         
    `  
    return (
        <Container>
            <CustomerGrid>
                <Card>
                    <CardHeader>
                        <Text textStyle="h3" content={`Migration status for client projects`} />
                    </CardHeader>
                    <CardContent hasScrollbar={false}>
                        <AccordionList>    
                            {releaseDefinitions.map((releaseDefinition) => 
                                <MigrationCustomer key={releaseDefinition.name} releaseDefinition={releaseDefinition}/>
                            )}
                        </AccordionList>           
                    </CardContent>
                </Card>
            </CustomerGrid>
        </Container>        
    )
}