import React from 'react';
import { Container } from "../Shared/Styles";
import styled from 'styled-components';
import CustomerComponent from "./CustomerComponent";

export default function Dashboard({ pat, releaseDefinitions }) {

    const CustomerGrid = styled.div`
        display: grid;
        margin-top: 20px; 
        grid-gap: 15px; 
        grid-template-columns: repeat(${3}, 1fr);
         
    `
    
    return (
        <Container>
            <CustomerGrid>
                {releaseDefinitions
                    .map(releaseDefinition => <CustomerComponent key={releaseDefinition.name} release={releaseDefinition} pat={pat} />)
                }
            </CustomerGrid>
        </Container>
    )
}