import React from "react";
import styled from 'styled-components';
import Text from '@embracesbs/component-text';
import ReleaseComponent from "./ReleaseComponent";
import Card from '@embracesbs/component-card';
import CardHeader from '@embracesbs/component-cardheader';
import CardContent from '@embracesbs/component-cardcontent';

export default function CustomerComponent({ release, pat }) {
    const ReleaseGrid = styled.div`
        display: grid;       
    `

    return (
        <Card>
            <CardHeader>
                <Text textStyle="h3" content={release.name} />
            </CardHeader>
            <CardContent hasScrollbar={false}>
                <ReleaseGrid columns={release.environments.length}>
                    {release.environments.map(environment => <ReleaseComponent environment={environment} pat={pat} />)}
                </ReleaseGrid>
            </CardContent>
        </Card>
    )
}