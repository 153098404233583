import React, { useState, useEffect } from 'react';
const axios = require('axios');

const useSurfaceApi = (hostname, apiKey, execute) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null)
    //const url = `https://portal.portaltemplate.localhost/umbraco/Surface/MigrationStatusSurface/GetStatus/`

    const url = `https://${hostname}/umbraco/Surface/MigrationStatusSurface/GetStatus/`

    useEffect(() => {
        if (!hostname || !apiKey) return;
    
        const fetchMigrationStatus = async () => {
            setLoading(true);
            await axios.get(url, { headers: {
                "Authorization": apiKey
            }})
            .then((response) => { return response})
            .then((response) => setData(response.data))
            .catch((error) => {console.log(error); setError(error)})
            .finally(setLoading(false))
        }
        
        if (execute) {
            fetchMigrationStatus();
        }
    }, [hostname, apiKey, execute])
    return { data, loading, error };
}

export default useSurfaceApi;