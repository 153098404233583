import React from "react";
import MigrationComponent from "./MigrationComponent";

export default function CustomerComponent({releaseDefinition}) {
    const { name, environments } = releaseDefinition
    //todo add tabs per environment?
    return (
            environments.length > 0 ? 
                environments.map((environment) =>      
                    <MigrationComponent customer={name} environment={environment} />      
                )
                : null
        )
}