import React, { useState } from "react";
import TextField from '@embracesbs/component-textfield';
import TextFieldWrapper from '@embracesbs/component-textfieldwrapper';
import Button from '@embracesbs/component-button';
import Text from '@embracesbs/component-text';
import { Container } from "../Shared/Styles";

export default function Settings({ pat, setPat }) {

    const [localPat, setLocalPat] = useState(pat)
    const handlePat = e => {
        const patValue = e.target.value
        setLocalPat(patValue)
    }

    function savePat() {
        if (localPat) {
            setPat(localPat)
            localStorage.setItem('embracePat', localPat.toLocaleString())
        }
    }

    return (
        <Container>
            <Text content="Embrace Pat, Place a AzureDevops pat that has read permissions" textSyle="h1" />
            <TextFieldWrapper >
                <TextField
                    type="password"
                    value={localPat}
                    onChange={handlePat}
                />
            </TextFieldWrapper>

            <Button text="Save Pat" onClick={savePat} />
        </Container>
    )
}