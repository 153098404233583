const axios = require('axios');

let organisation = 'embrace';
let project = 'portal';

let url = `https://vsrm.dev.azure.com/${organisation}/${project}/_apis/release/definitions?$expand=environments,variables&api-version=5.1`

function SetHeader(pat){
  axios.default.headers = {'Authorization': ''}
  axios.defaults.headers.common['Authorization'] = `Basic ${btoa(`:${pat}`)}`  
}
export function GetReleaseUrl(releaseId){
  return `https://dev.azure.com/${organisation}/${project}/_releaseProgress?_a=release-pipeline-progress&releaseId=${releaseId}`
}

export async function GetAllDefinitions(pat){
    try{
        SetHeader(pat);
        let url = `https://vsrm.dev.azure.com/${organisation}/${project}/_apis/release/definitions?$expand=environments,variables&api-version=5.1`
        return (await axios.get(url)).data
    }catch (error){
        handle(error)           
    } 
}

export async function GetReleaseData(url, pat){
    try {
        SetHeader(pat)
        let response = (await axios.get(url))        
        return response.status === 200 ? response.data : '';
        
    }catch (e) {
        handle(e)
    }
}

function handle(error){
    console.log(error);
    if (error.response) console.log(error.response.status, error.response.data.Message);
    if (error.config) console.log(error.config.url, error.config.method);

    throw error;
}