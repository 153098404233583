import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import logo from "../gfx/logo.svg";
import ReleaseComponent from "../Dashboard/ReleaseComponent";

import { theme, unit } from "@embracesbs/helpers";
import Sidebar from "@embracesbs/component-sidebar";
import SidebarHeader from "@embracesbs/component-sidebarheader";
import Image from "@embracesbs/component-image";
import SidebarSearch from "@embracesbs/component-sidebarsearch";
import SidebarContent from "@embracesbs/component-sidebarcontent";
import SidebarFooter from "@embracesbs/component-sidebarfooter";
import Divider from "@embracesbs/component-divider";
import Group from "@embracesbs/component-group";
import SectionHeader from "@embracesbs/component-sectionheader";
import List from "@embracesbs/component-list";
import ListItem from "@embracesbs/component-listitem";
import Icon from "@embracesbs/component-icon";
import Accordion from "@embracesbs/component-accordion";
import Checkbox from "@embracesbs/component-checkbox";
import Chip from "@embracesbs/component-chip";
import ScrollBar from "@embracesbs/component-scrollbar";
import Text from "@embracesbs/component-text";

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  height: ${unit(48)};
  padding: ${theme.spacing.$3} 0;

  p {
    color: ${theme.color.light$4};
    justify-content: center;
  }
`;

function DashboardSidebar({ releaseDefinitions, pat }) {
  const [search, setSearch] = useState(0);

  const mainTabs = [
    {
      id: 1,
      iconName: "LayoutDashboard",
      route: "/dashboard",
      content: "Project versions",
    },
    {
      id: 2,
      iconName: "PhoneActionsMerge",
      route: "/migrations",
      content: "Migrations",
    },
    {
      id: 3,
      iconName: "Cog",
      route: "/settings",
      content: "Settings",
    },
  ];

  // Import your navigation link from a library and use it as a property

  const initialState = {
    isExpanded: false,
    barAutoClosing: true,
    activeItemId: 2,
    searchTerm: "",
    searchType: null,
    searchPerson: null,
    recentSearches: [],
    isContextModalOpen: false,
  };

  const customers = releaseDefinitions.map((releaseDefinition) => ({
    name: releaseDefinition.name,
    environments: releaseDefinition.environments,
  }));

  const recentSearchChildren = (
    <Group
      isVertical
      justify="flex-start"
      spacing={{ top: theme.spacing.$5Number }}
    >
      <SectionHeader
        text="Recent Searches"
        spacing={{ bottom: theme.spacing.$3Number }}
      />
      <List>
        {initialState.recentSearches.map((term) => (
          <ListItem
            key={term}
            spacing={0}
            icon={{
              iconName: "TimeClockCircle",
              color: "gray",
              spacing: { right: theme.spacing.$2Number },
            }}
            title={term}
            onItemClick={() => setSearch({ searchTerm: term })}
            actions={
              <Icon
                iconName="Delete1"
                color="gray"
                spacing={{ right: theme.spacing.$4Number }}
                onClick={(e) => {
                  e.stopPropagation();
                  setSearch((prevState) => ({
                    recentSearches: prevState.recentSearches.filter(
                      (item) => item !== term
                    ),
                  }));
                }}
              />
            }
          />
        ))}
      </List>
    </Group>
  );

  const filterAccordion = (hasTopBorder, name, items, searchItem) => (
    <Accordion
      hasTopBorder={hasTopBorder}
      id={name}
      key={`${name}${initialState.searchTerm.length > 0}`}
      headerTitle={name}
      spacing={theme.spacing.$3Number}
      extraProps={["style", { StyledButton: { iconSize: 16 } }]}
      style={{ width: "100%", boxSizing: "border-box" }}
    >
      <Group isVertical align="left">
        {items.map((item) => (
          <Checkbox
            key={item}
            id={item}
            name={item}
            value={item}
            label={item}
            isChecked={search[searchItem] === item}
            onClick={() => {
              if (search[searchItem] === item)
                setSearch({ [searchItem]: null });
              else setSearch({ [searchItem]: item });
            }}
            extraProps={["onChange"]}
            onChange={() => { }}
          />
        ))}
      </Group>
    </Accordion>
  );

  const searchFilterChildren = (
    <Group isVertical>
      <SectionHeader
        text="Filter"
        spacing={{ bottom: theme.spacing.$3Number }}
      />
      {filterAccordion(true, "Types", ["Customers"], "searchType")}
    </Group>
  );

  const searchResultsGroup = (name, data, filterAttributes, listItemRender) => {
    if (search.searchType && search.searchType !== name) return null;

    const filteredData = data.filter((item) =>
      filterAttributes.some((attr) =>
        new RegExp(search.searchTerm, "i").test(item[attr])
      )
    );

    if (!filteredData.length)
      return (
        <SectionHeader
          text={`No ${name} found`}
          spacing={{ bottom: theme.spacing.$3Number }}
        />
      );

    return (
      <Group isVertical justify="flex-start">
        <SectionHeader
          text={name}
          spacing={{ bottom: theme.spacing.$3Number }}
        />
        {filteredData.map(listItemRender)}
      </Group>
    );
  };

  const searchResultsChildren = (
    <ScrollBar
      extraProps={["renderTrackHorizontal"]}
      renderTrackHorizontal={() => <div />}
    >
      <Group isVertical spacing={{ top: theme.spacing.$3Number }}>
        <Group align="left">
          {["Customers"].map((text) => (
            <Chip
              key={text}
              text={text}
              isSelected={search.searchType === text}
              onClick={() => {
                if (search.searchType === text) setSearch({ searchType: null });
                else setSearch({ searchType: text });
              }}
            />
          ))}
        </Group>
        {searchResultsGroup(
          "Customers",
          customers,
          ["name", "environments"],
          ({ name, environments }) => (
            <Group
              align="left"
              spacing={{
                right: theme.spacing.$6Number,
                left: theme.spacing.$6Number,
                top: theme.spacing.$6Number,
                bottom: 0,
              }}
              isVertical
            >
              <Text content={name} textStyle="body2" />
              <Group justify="flex-start">
                {environments.map((environment, index) => {
                  return (
                    <React.Fragment key={index}>
                      <ReleaseComponent environment={environment} pat={pat} />
                      {/* <Button
                        variant="link"
                        href={
                          environment.variables["Portal.Hostname"] &&
                          `https://${environment.variables["Portal.Hostname"].value}`
                        }
                        text={environment.name}
                      /> */}
                    </React.Fragment>
                  );
                })}
              </Group>
            </Group>
          )
        )}
      </Group>
    </ScrollBar>
  );

  const activeBackgroundColor = "rgba(255,255,255,0.06)";

  const sideBarcontent = (
    <React.Fragment>
      <SidebarSearch
        onChange={(searchTerm) => setSearch({ searchTerm })}
        searchTerm={search.searchTerm}
        recentSearchChildren={recentSearchChildren}
        searchFilterChildren={searchFilterChildren}
        searchResultsChildren={searchResultsChildren}
      />
      <SectionHeader text="Menu" />
      <List>
        {mainTabs.map((option, index) => (
          <ListItem
            spacing={0}
            id={option.id}
            key={option.id}
            activeTabBackgroundColor={activeBackgroundColor}
            navLink={Link}
            navLinkProps={{ to: option.route }}
            isActive={option.id === search.activeItemId}
            onItemClick={(activeItemId) => {
              if (index === 0) {
                setSearch({
                  isExpanded: true,
                  barAutoClosing: false,
                  activeItemId,
                });
              } else {
                setSearch({ ...initialState, activeItemId });
              }
            }}
            title={option.content}
            icon={{
              iconName: option.iconName,
              color: "#fff",
            }}
          />
        ))}
      </List>
    </React.Fragment>
  );

  return (
    <Sidebar
      extraProps={["style"]}
      style={{ position: "fixed", height: "100%", top: "0", left: "0" }}
      isExpanded={search.expanded}
      isBarAutoClosing={search.barAutoClosing}
    >
      <SidebarHeader>
        <LogoWrapper>
          <Image src={logo} width={20} height={20} />
          <SectionHeader text="Customer Portals" />
        </LogoWrapper>
        <Divider
          spacing={{
            top: theme.spacing.$5Number,
            bottom: theme.spacing.$5Number,
          }}
          background="dark$7"
        />
      </SidebarHeader>
      <SidebarContent content={sideBarcontent} />
      <SidebarFooter></SidebarFooter>
    </Sidebar>
  );
}

export default DashboardSidebar;
