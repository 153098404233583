import React, { useState, useEffect } from "react";
import Text from '@embracesbs/component-text';
import Accordion from '@embracesbs/component-accordion';
import AccordionList from '@embracesbs/component-accordionlist';
import Group from '@embracesbs/component-group';
import Button from '@embracesbs/component-button';
const api = require('../Api/embraceAzure')

export default function ReleaseComponent({ environment, pat }) {
    const [release, setRelease] = useState();

    useEffect(() => {
        if (environment && environment.currentRelease && environment.currentRelease.id !== 0 && pat) {
            api.GetReleaseData(environment.currentRelease.url, pat)
                .then(x => setRelease(x))
        }
    }, [environment])

    const releaseUrl = environment && environment.currentRelease && environment.currentRelease.id !== 0
        ? <Button href={api.GetReleaseUrl(environment.currentRelease.id)} target="_blank" text="Release" variant="link" target="_blank" /> : '';

    const customersLink = environment && environment.variables["Customers.BaseHostname"] ?
        <Button href={`https://${environment.variables["Customers.BaseHostname"].value}/go`} text="Customers" variant="link" target="_blank" /> : '';

    const portalLink = environment && environment.variables["Portal.Hostname"]
        ? <Button href={`https://${environment.variables["Portal.Hostname"].value}/`} text="Portals" variant="link" target="_blank" />
        : environment && environment.variables["Portal.HostName"]
            ? <Button href={`https://${environment.variables["Portal.HostName"].value}/`} text="Portals" variant="link" target="_blank" /> : '';

    const artifact = release && release.artifacts && release.artifacts.find(artifact => artifact.isPrimary)

    const VersionInfo = artifact && artifact.definitionReference
        ?
        <Text content={`Deployed Version: ${artifact.definitionReference.version.name}`} textStyle="body" />
        : '';

    const ReleasedOn = release
        ?
        <Text content={`Deployed on: ${release.modifiedOn}`} textStyle="body" />
        : '';

    return (
        <AccordionList>
            <Accordion headerTitle={environment.name} id={environment.id} hover="full">
                {VersionInfo}
                {ReleasedOn}
                <Group justify="flex-start" spacing={{ top: 16 }}>
                    {portalLink}
                    {customersLink}
                    {releaseUrl}
                </Group>
            </Accordion>
        </AccordionList>
    )
}