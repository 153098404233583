import React, { useState } from "react";

import ProgressIndicator from '@embracesbs/component-progressindicator';
import Accordion from '@embracesbs/component-accordion';
import Table from '@embracesbs/component-table';
import TableRow from '@embracesbs/component-tablerow'; // A React Native version is available!
import Text from '@embracesbs/component-text'; // A React Native version is available!
import Icon from '@embracesbs/component-icon'; // A React Native version is available!s
import { theme } from '@embracesbs/helpers/dist';

import useSurfaceApi from '../Api/useSurfaceApi';

export default function MigrationComponent({customer, environment}) {

    const { variables } = environment
    const [isOpen, setOpen] = useState(false)
    
    //TODO fix pipeline variables with different casing, Hostname !== HostName
    const hostname = variables['Portal.Hostname'] ? variables['Portal.Hostname'].value : variables['Portal.HostName'] ? variables['Portal.HostName'].value : null
    const apiKey = variables['Portal.CustomersApiKey'] ? variables['Portal.CustomersApiKey'].value : null    

    const { data, loading, error } = useSurfaceApi(hostname, apiKey, isOpen)

    return ( 
        <React.Fragment>                     
            <Accordion id={`${customer}${environment.name}`} headerTitle={`${customer} - ${environment.name}`} hover="full" onArrowToggle={() => setOpen(!isOpen)} isOpen={isOpen}>

                {loading && <ProgressIndicator variant="circular" />}
                {error && "Could not retrieve migrationstatus"}
                {data.length > 0 &&                                
                    <Table columnStyle={[{ width: 50 }]} border={{ isFullWidth: false, hasBorder: true }}>             
                        {data.map((Migration) => 
                            <MigrationRow 
                                key={Migration.Name}
                                Name={Migration.Name} 
                                DocTypeAlias={Migration.DocTypeAlias}
                                Migrated={Migration.IsMigrated}/>
                        )} 
                    </Table>                     
                }
            </Accordion>  
        </React.Fragment>
    )
}

const MigrationRow = ({Name, DocTypeAlias, Migrated}) => {
    const color = Migrated ? theme.color.success : theme.color.danger
    const icon = Migrated ? "CheckCircle1" : "Delete1"
    const text = Migrated ? "Migrated" : "Not Migrated"
    return (
        <React.Fragment>
            <TableRow>
                <Icon size={20} iconName={icon} color={color} />
                <Text content={`${Name} -  ${DocTypeAlias} - ${text}`} textColor={color} />
            </TableRow>
        </React.Fragment>
    )
}